export default {
    'header.help': 'সহায়তা',
    'header.account': 'একাউন্ট',
    'header.welcome': '{msg} এ স্বাগতম',
    'header.signOut': 'সাইন আউট',
    'header.join': 'যোগদান',
    'header.signIn': 'সাইন ইন',
    'header.search': 'পণ্য অনুসন্ধান করুন ..',
    'header.login.signIn': 'সাইন ইন',
    'header.login.emailOrPhone': 'ইমেল বা ফোন নম্বর',
    'header.login.empty': 'আপনি এটিকে খালি রাখতে পারবেন না।',
    'header.login.maximum': 'সর্বাধিক {msg} অক্ষর আপনি ইনপুট হতে পারেন',
    'header.login.minimum': 'সর্বনিম্ন {msg} অক্ষর আপনি ইনপুট হতে পারেন',
    'header.login.password': 'পাসওয়ার্ড',
    'header.login.forgotPassword': 'পাসওয়ার্ড ভুলে গেছেন',
    'header.login.register': 'নিবন্ধন',
    'header.login.name': 'আপনার প্রথম এবং শেষ নাম লিখুন',
    'header.login.conPassword': 'পাসওয়ার্ড নিশ্চিত করুন',
    'header.login.passrequired': 'আপনার পাসওয়ার্ড ইনপুট করুন!',
}
